/// <reference types="cordova-plugin-inappbrowser" />
import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import Axios from 'axios';

import { localStorageGet, localStorageRemove, localStorageSet } from '~/app/helpers/localStorage';

import { isCordova } from '~/cordova';

import { changeLanguage, Languages } from '~/i18n';

import { LoginResponse, Permissions, PermissionResponse, Role, User, PermissionOffline, MachineActiveTabs, UserPayload } from '~/types';

import { StoreModel } from '~/store';

import { tenantCode, TenantErrorCodes } from '~/tenantProvider/config';

import { MSALBrowser } from '~/auth/msalBrowser';
import { MSALCordova } from '~/auth/msalCordova';
import { fetch } from "~/store/commonStore";
import { API } from "~/app/routes";
import { changeBaseURL } from '~/tenantProvider/helpers';
import { fetchJWTToken } from '../axiosHelper';
import { TFunction } from 'i18next';

export type TokenData = JwtPayload & {
  email?: string
  tid?: string
  extension_tenantid?: string
  name?: string
  given_name?: string
  family_name?: string
  job_title?: string
  scp?: string
  azp?: string
  ver?: string
}

export type UserModel = {
  token: string | null
  setToken: Action<UserModel, UserModel['token']>
  tokenData: Computed<UserModel, TokenData | null>
  data: User
  setData: Action<UserModel, User>

  isUserAuthenticated: Computed<UserModel>

  updating: boolean
  setUpdating: Action<UserModel, boolean>

  checkCurrentLoginUser: Thunk<UserModel, void, any, StoreModel, Promise<LoginResponse['payload']['data']>>

  logout: Thunk<UserModel, void | { microsoftLogout?: boolean }, any, StoreModel, Promise<void>>

  roles: Role[]
  fetchingRoles: boolean
  setFetchingRoles: Action<UserModel, boolean>
  fetchRoles: Thunk<UserModel, void, any, StoreModel, Promise<Role[]>>
  fetchedRoles: Action<UserModel, Role[]>

  currentRole: Role['id'] | undefined
  setCurrentRole: Action<UserModel, Role['id'] | undefined>

  permissions: Permissions
  fetchingPermissions: boolean
  setFetchingPermissions: Action<UserModel, boolean>
  fetchPermissions: Thunk<UserModel, Role['id'] | void, any, StoreModel, Promise<PermissionResponse>>
  fetchedPermissions: Action<UserModel, Permissions>

  updateLanguage: Thunk<UserModel, Partial<UserPayload>, any, StoreModel, Promise<null>>
  updatedLanguage: Action<UserModel, UserPayload["language"]>
  changeLanguage: Thunk<UserModel, Languages, any, StoreModel, Promise<TFunction>>
}

const currentUser = JSON.parse(localStorageGet('currentUser') as string) as User;
const offlinePermissions = JSON.parse(localStorageGet('permissions') as string) as PermissionOffline | undefined;
export const emptyUser = {
  id: '',
  username: '',
  name: '',
  email: '',
  employee_numner: '',
  phone: '',
  mobile: '',
  job_title: '',
  department: '',
  is_active: true,
  language: Languages.NORWEGIAN,
  created_at: '',
  updated_at: ''
};

type GetUserDataByJWTPayload = {
  code: number
  payload: {
    data: User
  }
}

export const userModel: UserModel = {
  token: localStorageGet('token'),
  setToken: action((state, payload) => {
    state.token = payload;
    
    if (payload) {
      localStorageSet('token', payload);
    } else {
      localStorageRemove('token');
    }
  }),
  tokenData: computed(state => (state.token ? jwtDecode(state.token) : null)),
  
  data: {...emptyUser, ...currentUser},
  setData: action((state, payload) => {
    state.data = { ...payload };
    localStorageSet('currentUser', JSON.stringify(payload));
  }),

  isUserAuthenticated: computed(state => (state.token && state.data.id)),

  updating: false,
  setUpdating: action((state, payload) => {
    state.updating = payload;
  }),

  checkCurrentLoginUser: thunk((actions, payload, { getStoreState }) => (
    new Promise((resolve, reject) => {
      const { currentTenant } = getStoreState().tenant;

      const checkNotificationPermission = async (): Promise<string | void> => {
        return new Promise(function (resolve, reject) {
          window['FirebasePlugin'].hasPermission(function (hasPermission) {
            if (hasPermission) {
              // Granted
              resolve();
            } else {
              // Request permission
              window['FirebasePlugin'].grantPermission(function (hasPermission) {
                if (hasPermission) {
                  resolve();
                } else {
                  reject();
                }
              });

            }
          });
        })
      };

      const firebaseAPNSTokenPromise = async (): Promise<string | void> => {
        return new Promise(function (resolve, reject) {
          window['FirebasePlugin'] ? window['FirebasePlugin'].onApnsTokenReceived(function (token) {
            resolve(token)
          }, function (error) {
            reject();
          }) : reject();
        })
      };

      const firebaseTokenPromise = async (): Promise<string | void> => {
        return new Promise(function (resolve, reject) {
          if (window['FirebasePlugin']) {
            checkNotificationPermission().then(() => {
              (window['cordova']['platformId'] === 'ios' ? firebaseAPNSTokenPromise() : Promise.resolve())
                .then(() => {
                  window['FirebasePlugin'].getToken(function (token) {
                    resolve(token)
                  }, function (error) {
                    resolve()
                  })
                }).catch(() => resolve())
            }).catch(() => {
              resolve();
            })
          } else {
            resolve();
          }
        })
      };

      const getBackendUrlFromTenant = async (): Promise<string | void> => {
        // Fetch latest token
        let newToken: string | null = await fetchJWTToken();

        return new Promise(function (resolve, reject) {
          const newTokenData: TokenData = newToken ? jwtDecode(newToken) : {};
          const extensionTenantid = newTokenData?.extension_tenantid ? newTokenData?.extension_tenantid : '';
          if (extensionTenantid && currentTenant) {
            Axios.get<any, any>(
              `${currentTenant?.baseUrl}/get_backend_url_from_tenant`,
              { params: { extension_tenantid: extensionTenantid } }
            )
              .then(response => {
                if(response.success) {
                  if(response?.apiUrl) {
                    currentTenant.baseUrl = changeBaseURL(tenantCode, response.apiUrl);
                  }
                  resolve();
                } else {
                  reject();
                }
              })
              .catch(reject);
          } else {
            reject();
          }
        });
      };

      (window['cordova'] ? firebaseTokenPromise() : Promise.resolve()).then(async (deviceToken) => {
        // First, call get_backend_url_from_tenant to get backend url from current tenant
        getBackendUrlFromTenant().then(() => {
          Axios.get<GetUserDataByJWTPayload, GetUserDataByJWTPayload>(
            `${currentTenant?.baseUrl}/get_user_data_by_ms_jwt`,
            { params: { deviceToken } },
          )
            .then(({ payload: { data } }) => {
              actions.setData(data);
              actions.changeLanguage(data.language);
              resolve(data);
            })
            .catch(reject);
        }).catch(reject);
      });
    })
  )),

  logout: thunk((actions, payload, { getStoreState, getStoreActions }) => (
    new Promise<void>((resolve, reject) => {
      const currentTenant = getStoreState().tenant.currentTenant;
      const tenantError = getStoreState().tenant.tenantError;
      const redirectionUri = isCordova ? `${window['BuildInfo']?.packageName}://oauth_callback` : window.location.origin + process.env.PUBLIC_URL;
      const b2cSessionClearUri = `${currentTenant?.azureADB2CAuthority}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectionUri}`;
      const postLogoutRedirectUri = `${process.env.REACT_APP_MICROSOFT_ACCOUNT_SIGN_OUT_URL}?post_logout_redirect_uri=${b2cSessionClearUri}`;
      const isExternal = (tenantError && tenantError.code === TenantErrorCodes.externalTenantErrorCode) || getStoreState().user.data.isInternalUser === false;
      const redirectToExternalLogout = payload && payload.microsoftLogout && isExternal;

      (isCordova ? MSALCordova.signOut() : MSALBrowser.logoutRedirect({...(redirectToExternalLogout && {postLogoutRedirectUri})}))
        .then(() => {
          actions.setToken(null);
          actions.setData(emptyUser);

          getStoreActions().tenant.setTenantError(undefined);
          redirectToExternalLogout && window.open(postLogoutRedirectUri, 'oauth:microsoftonline', '');

          resolve();
        })
        .catch(reject);
    })
  )),

  roles: [],
  fetchingRoles: false,
  setFetchingRoles: action((state, payload) => {
    state.fetchingRoles = payload;
  }),
  fetchRoles: thunk((actions) => {
    actions.setFetchingRoles(true);

    return fetch<Role>(`${API.users}/roles`)
      .then(data => {
        actions.fetchedRoles(data);
        return data;
      })
      .finally(() => {
        actions.setFetchingRoles(false);
      });
  }),
  fetchedRoles: action((state, payload) => {
    state.roles = payload;
  }),

  currentRole: offlinePermissions?.roleId,
  setCurrentRole: action((state, payload) => {
    state.currentRole = payload;
  }),


  permissions: {...offlinePermissions?.permissions},
  fetchingPermissions: false,
  setFetchingPermissions: action((state, payload) => {
        state.fetchingPermissions = payload;
  }),
  fetchPermissions: thunk((actions, payload, { getStoreState, getStoreActions }) => {
    actions.setFetchingPermissions(true);

    const offlineRoleId = offlinePermissions && offlinePermissions.userId === getStoreState().user.data.id ? offlinePermissions.roleId : undefined;
    const roleId = payload ? payload : offlineRoleId;
    return Axios.get<PermissionResponse, PermissionResponse>(`${API.users}/permissions${roleId ? '/' + roleId : ''}`)
      .then(data => {
        const permissions: PermissionOffline = {
          userId: getStoreState().user.data.id,
          ...data
        }
        localStorageSet('permissions', JSON.stringify(permissions));

        actions.fetchedPermissions(data.permissions);
        actions.setCurrentRole(data.roleId);
        getStoreActions().machine.setDefaultTab(data.appSettings.assetDefaultSelectedTab as MachineActiveTabs['activeTab']);
        getStoreActions().machine.initActiveTab();
        return data;
      })
      .finally(() => {
        actions.setFetchingPermissions(false);
      });
  }),
  fetchedPermissions: action((state, payload) => {
    state.permissions = payload;
  }),

  // Call Update User Language API
  updateLanguage: thunk((actions, payload, { getState, getStoreActions }) => {
    
    if(!payload || !payload.language) {
      return Promise.resolve(null);
    }
      
    actions.setUpdating(true);
    return Axios.post<null, null>(`${API.users}${API.updateLanguage}`, payload)
      .then(() => {
        actions.updatedLanguage(payload.language);
        return null;
      })
      .finally(() => {
        actions.setUpdating(false);
      });
  }),

  // Set updated language in user state
  updatedLanguage: action((state, payload: Languages) => {
    state.data.language = payload;
    localStorageSet('currentUser', JSON.stringify(payload));
  }),

  // Change language
  changeLanguage: thunk((actions, payload) => {
    return changeLanguage(payload);
  })
};
